.photo
	&__item
		position relative
		display block
		overflow hidden
		margin-bottom 10px
		outline none
		border-radius 10px
		background-color alpha($black, .1)
		background-position center
		background-size cover
		background-repeat no-repeat
		transition .3s all ease

		&:hover
			&:before, &:after
				opacity 1
				pointer-events none

		&:before, &:after
			position absolute
			content ''
			opacity 0
			transition .3s all ease

		&:before
			top 0
			right 0
			bottom 0
			left 0
			border-radius 10px
			background-color alpha($black, .25)

		&:after
			top 50%
			left 50%
			width 80px
			height 80px
			border-radius 50%
			background-color alpha($white, .3)
			background-image url('/static/images/photo/photo__plus.svg')
			background-position center
			background-repeat no-repeat
			transform translate(-50%, -50%)

		&-certificate
			border 1px solid alpha($black, .05)

		&-alone
			&:after, &:before
				display none

		&-black
			&:after
				top 0
				right 0
				bottom 0
				left 0
				display block
				width auto
				height auto
				border-radius 0
				background-color alpha($black, .4)
				background-image none
				opacity 1
				transform translate(0)

		img
			max-width 100%
			height auto

	&__title
		position absolute
		right 4em
		bottom 4rem
		left 4rem
		z-index 1
		color $white

		@media (max-width: $grid-sm)
			right 2em
			bottom 2rem
			left 2rem
			font-size 2.2rem
