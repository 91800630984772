.search
	position relative
	border-bottom 1px solid alpha($white, .2)
	font-size 1.3rem


	input
		width 100%
		padding-bottom 5px
		border none
		background transparent
		color $white
		outline none

		&::placeholder
			color alpha($white, .3)


	button
		content ''
		right 0
		bottom 5px
		position absolute
		width 20px
		height 20px
		border none
		background-image url('/static/images/menu/menu__search.svg')
		background-repeat no-repeat
		background-color transparent
		cursor pointer
		outline none
		opacity .25
		transition .3s opacity ease

		&:hover
			opacity .35
