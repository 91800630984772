.arrow
	display inline-block
	width 10px
	height 18px
	background-repeat no-repeat
	background-position center
	background-image url('/static/images/arrows/arrow.svg')
	opacity .2
	outline none

	&:hover
		opacity 1

	&-left
		transform rotateY(180deg)


	&__block
		display flex
		align-items center


	&__points
		margin 0 15px
		padding 0

		li
			display inline-block
			list-style none

	&__point
		display inline-block
		position relative
		top -3px
		width 4px
		height 4px
		margin-left 15px
		margin-right 15px
		border-radius 50%
		background-color alpha($black, .2)
		outline none

		&:hover
			background-color alpha($black, .3)

		&-active
			background-color $black
