.menu
	position fixed
	top 0
	right 0
	left 0
	bottom 0
	font-size 1.3rem
	z-index 151
	overflow-y auto
	pointer-events none

	&-show
		pointer-events all

		.menu__contacts
			box-shadow 0 15px 50px 0 rgba(0, 0, 0, .15)
			transform translateY(0)
			pointer-events all

		.menu__main
			box-shadow 0 15px 250px 0 rgba(0, 0, 0, .15)
			transform translateY(0)
			pointer-events all


	&__row

		@media (max-width $grid-md - 1)
			flex-direction column-reverse


	&__container
		max-width 1200px

		@media (max-width $grid-md)
			width 100%
			padding-right 0
			padding-left 0


	&__contacts
		padding 60px 30px 50px 30px
		background-color #eeeeee
		transform translateY(-100%)
		transition .5s all ease

		@media (max-width $grid-md - 1)
			padding 40px 0 35px 40px
			transform translateX(100vw)

		a
			line-height (18 / 13)


	&__title
		margin-bottom 10px
		color #949494


	&__address
		margin-bottom 40px
		padding-top 10px
		font-size 1.3rem
		line-height 1.2

		@media (max-width $grid-md)
			margin-bottom 10px

		& > div
			margin-bottom 15px

			@media (max-width $grid-md)
				margin-bottom 10px

			&:nth-last-child()
				margin-bottom 0


	&__main
		padding 60px 80px 80px
		background-color $white
		transform translateY(-100%)
		transition .4s all ease

		@media (max-width $grid-md - 1)
			padding 20px 40px
			transform translateX(100vw)


	&__social
		filter invert(1)

		.social
			margin-left 0
			border-color transparent

			&:hover
				opacity .3


	&__search
		margin-right 50px
		margin-bottom 60px
		filter invert(1)

		// input  //
		// 	filter invert(1) //

		// button  //
		// 	color $white //

		@media (max-width $grid-md)
			margin-right 0
			margin-bottom 40px


	&__nav
		margin 0
		padding 0

		li
			margin-bottom 20px
			list-style none

			&:last-child
				margin 0

		a
			font-size 1.6rem
			color $black

			&:hover
				color #9e9e9e

		@media (max-width $grid-md)
			margin-bottom 20px


	&__namewrap
		text-align right

		@media (max-width $grid-md - 1)
			display none

		&-mobile
			display none
			margin-bottom 30px
			text-align center

			@media (max-width $grid-md - 1)
				display block


	&__name
		position relative
		padding-left 50px
		line-height 40px
		color $black
		transition all .4s ease

		&:before
			position absolute
			content ''
			top 50%
			left 0
			width 40px
			height 40px
			border 1px solid $black
			border-radius 50%
			background-image url('/static/images/menu/menu__close.svg')
			background-repeat no-repeat
			background-size 35%
			background-position center
			transform translateY(-50%)
			opacity .2
			transition .3s opacity ease

		&:hover
			color $black

			&:before
				opacity .3

		@media (max-width $grid-md - 1)
			padding-left 40px

			&:before
				top 0
				left 50%
				transform translate(-50%, 0)
