.text-justify
    text-align justify !important

.text-nowrap
    white-space nowrap !important

.text-truncate
    overflow hidden
    text-overflow ellipsis
    white-space nowrap

.text-left
    text-align left !important

.text-right
    text-align right !important

.text-center
    text-align center !important

@media (min-width: $grid-sm)
    .text-sm-left
        text-align left !important
    .text-sm-right
        text-align right !important
    .text-sm-center
        text-align center !important

@media (min-width: $grid-md)
    .text-md-left
        text-align left !important
    .text-md-right
        text-align right !important
    .text-md-center
        text-align center !important

@media (min-width: $grid-lg)
    .text-lg-left
        text-align left !important
    .text-lg-right
        text-align right !important
    .text-lg-center
        text-align center !important

@media (min-width: $grid-xl)
    .text-xl-left
        text-align left !important
    .text-xl-right
        text-align right !important
    .text-xl-center
        text-align center !important

.text-lowercase
    text-transform lowercase !important

.text-uppercase
    text-transform uppercase !important

.text-capitalize
    text-transform capitalize !important

.font-weight-normal
    font-weight $font-weight-normal

.font-weight-bold
    font-weight $font-weight-bold

.font-italic
    font-style italic

.text-white
    color $white !important

.text-primary
    color $primary !important

a.text-primary:focus, a.text-primary:hover
    color $primaryHov !important

.text-secondary
    color $secondary !important

a.text-secondary:focus, a.text-secondary:hover
    color $secondaryHov !important

.text-success
    color $success !important

a.text-success:focus, a.text-success:hover
    color $successHov !important

.text-info
    color $info !important

a.text-info:focus, a.text-info:hover
    color $infoHov !important

.text-warning
    color $warning !important

a.text-warning:focus, a.text-warning:hover
    color $warningHov !important

.text-danger
    color $danger !important

a.text-danger:focus, a.text-danger:hover
    color $dangerHov !important

.text-light
    color $light !important

a.text-light:focus, a.text-light:hover
    color $lightHov !important

.text-dark
    color $dark !important

a.text-dark:focus, a.text-dark:hover
    color $darkHov !important

.text-muted
    color $text-muted !important

.text-hide
    font 0/0 a
    color transparent
    text-shadow none
    background-color transparent
    border 0

.text-underline
    text-decoration underline
