@import 'link'

.pswp

	&__caption__center
		text-align center !important

		a
			@extend .link
			@extend .link-white
