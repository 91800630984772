.video
	display block
	position relative
	padding-top (9 * 100 / 16)%
	background-size cover
	background-position center
	background-repeat no-repeat
	background-blend-mode multiply
	background-color alpha($black, .1)
	transition .3s all ease

	&:hover
		background-color alpha($black, .3)

		&:before
			border-color $white

		&:after
			border-color transparent transparent transparent $white
			transform translate(-50%, -50%) scale(1.1)

	&:before
		content ''
		position absolute
		top 50%
		left 50%
		transform translate(-50%, -50%)
		width 80px
		height 80px
		border 2px solid alpha($white, .5)
		border-radius 50%
		transition .3s all ease

	&:after
		content ''
		display block
		position absolute
		top 50%
		left 51%
		transform translate(-50%, -50%)
		width 0
		height 0
		border-style solid
		border-width 20px 0 20px 30px
		border-color transparent transparent transparent alpha($white, .5)
		outline none
		transition .3s all ease
