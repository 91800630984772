.container
    margin-right auto
    margin-left auto
    padding-right 10px
    padding-left 10px
    width 100%

    @media (max-width $grid-sm)
        padding-left 20px
        padding-right 20px

@media (min-width: $grid-sm)
    .container
        max-width $container-sm

@media (min-width: $grid-md)
    .container
        max-width $container-md

@media (min-width: $grid-lg)
    .container
        max-width $container-lg

@media (min-width: $grid-xl)
    .container
        max-width $container-xl
