@font-face
	font-family 'Stolzl'
	src url('/static/fonts/stolzl-book-webfont.woff2') format('woff2'),
		url('/static/fonts/stolzl-book-webfont.woff') format('woff')
	font-weight 350
	font-style normal


@font-face
	font-family 'Stolzl'
	src url('/static/fonts/stolzl-regular-webfont.woff2') format('woff2'),
		url('/static/fonts/stolzl-regular-webfont.woff') format('woff')
	font-weight 400
	font-style normal



html,
body
	-webkit-font-smoothing antialiased
	text-rendering optimizeLegibility !important


html
	font-size 10px
	font-family 'Stolzl', 'Open Sans', sans-serif


body
	font-size 1.3rem


a,
button
	transition .3s all ease
