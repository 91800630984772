.slider
	position relative
	margin-top -($desktop)px
	background-color #aeaeae
	height 700px
	overflow hidden

	@media (max-width $grid-sm)
		height auto

	&__border
		position absolute
		z-index 100
		height 20px
		background-color $white

		&-left
			bottom 0
			left 0
			right calc(50% + 20px)

			&:after
				content ''
				position absolute
				top 0
				right -20px
				width 0
				height 0
				border-style solid
				border-width 20px 0 0 20px
				border-color transparent transparent transparent $white

		&-right
			bottom 0
			right 0
			left calc(50% + 20px)

			&:before
				content ''
				position absolute
				top 0
				left -20px
				width 0
				height 0
				border-style solid
				border-width 0 0 20px 20px
				border-color transparent transparent $white transparent


	&__slide
		background-repeat no-repeat
		background-size cover
		background-position center
		background-color gray


	&__content
		display flex
		flex-direction column
		justify-content center
		height 470px
		margin-top 150px
		margin-bottom 80px
		padding 0 20px

		@media (max-width $grid-sm)
			height 250px
			margin-top 120px


	&__title
		font-size 3.2rem
		color $white

		@media (max-width $grid-sm)
			font-size 2rem


	&__link
		color $white
		opacity .7

		&:hover
			color $white
			text-decoration none
			opacity 1.0

			&:after
				opacity .5


	&__framewrap
		position absolute
		top 0
		right 0
		left 0
		z-index 75
		pointer-events none


	&__frame
		position relative
		height 470px
		margin-top 150px
		margin-bottom 60px
		border 1px solid $white
		border-radius 15px

		@media (max-width $grid-sm)
			margin-top 140px
			height 250px


	&__points
		display flex
		position absolute
		left calc(50%)
		bottom 40px
		margin 0
		padding 0
		transform translate(-50%, 0%)
		pointer-events all

		li
			list-style none

		@media (max-width $grid-sm)
			bottom -20px

		.arrow__point
			display inline-block
			top 0
			width 4px
			height 4px
			margin-left 15px
			margin-right 15px
			border 2px solid $white
			border-radius 50%
			outline none

			&:hover
			&-active
				transform scale(3)
				transform-origin center center


	&__arrow
		display flex
		justify-content space-between
		position absolute
		bottom 40px
		z-index 101
		pointer-events all

		&-left
			left 40px

			@media (max-width $grid-sm)
				left 20px
				bottom 20px

		&-right
			right 40px

			@media (max-width $grid-sm)
				right 20px
				bottom 20px


	&__arrowimage
		width 40px
		height 18px
		background-repeat no-repeat
		background-position center
		transition .3s opacity ease
		outline none

		&-left
			background-image url('/static/images/slider/slider__arrow-left.svg')
			opacity .4

			&:hover
				opacity 1

		&-right
			background-image url('/static/images/slider/slider__arrow-right.svg')
			opacity .4

			&:hover
				opacity 1
