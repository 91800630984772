.modal
	position fixed
	top 0
	right 0
	bottom 0
	left 0
	z-index 153
	visibility hidden
	overflow-y auto
	transition-delay .3s

	&-show
		transition .3s visibility ease
		visibility visible

		.modal__overlay
			opacity 1

		.modal__block
			opacity 1
			transition-delay .3s
			transform translate(-50%, -50%)

	&__overlay
		position fixed
		top 0
		right 0
		bottom 0
		left 0
		z-index 154
		background-color alpha($black, .5)
		opacity 0
		transition opacity .3s ease
		transform translateZ(-1000px)

	&__block
		position absolute
		top 50%
		left 50%
		z-index 155
		padding 60px 40px
		width 550px
		border 1px solid $white
		border-radius 10px
		background-color $white
		opacity 0
		transition all .3s ease
		transform translate(-50%, -30%)

		@media (max-width: $grid-sm)
			overflow-y auto
			padding 50px 20px
			width 100%
			transform translate(-50%, -30%) !important

	&__close
		position absolute
		top 40px
		right -50px
		width 20px
		height 20px
		filter invert(1)
		background-image url('/static/images/menu/menu__close.svg')
		content ''
		opacity .3
		transition .3s opacity ease

		@media (max-width: $grid-sm)
			top -50px
			right 40px

		&:hover
			opacity .4

	&-night
		font-size 1.8rem
		$night-bg-color = lighten(#05263f, 5%)
		$text-color = #eefaff
		background linear-gradient(to top, $night-bg-color, lighten($night-bg-color, 10%))

		.modal__block
			padding-top 30px
			padding-bottom 30px
			color $text-color
