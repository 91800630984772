.subnav
	display none
	position absolute
	margin 0
	margin-right 60px
	padding 0
	list-style none
	font-weight 400
	border-radius 5px
	transition .3s box-shadow ease

	@media (max-width: $grid-md)
		display none

	&-fixed
		position fixed
		box-shadow 0 0 20px alpha($black, .1)


	&__item
		width 180px
		border 1px solid alpha($black, .1)
		border-bottom-color transparent
		box-sizing border-box

		&:first-child
			border-top-left-radius 5px
			border-top-right-radius 5px

		&:last-child
			border-bottom-left-radius 5px
			border-bottom-right-radius 5px
			border-bottom-color alpha($black, .1)


	&__a
		display inline-block
		padding 16px
		color $black

		&-active,
		&:focus,
		&:active
			color alpha($black, .5)
