.mw-100
    max-width 100% !important

.mh-100
    max-height 100% !important

.m-0
    margin 0 !important

.mt-0
    margin-top 0 !important

.mr-0
    margin-right 0 !important

.mb-0
    margin-bottom 0 !important

.ml-0
    margin-left 0 !important

.mx-0
    margin-right 0 !important
    margin-left 0 !important

.my-0
    margin-top 0 !important
    margin-bottom 0 !important

.m-1
    margin $helper-size-1 !important

.mt-1
    margin-top $helper-size-1 !important

.mr-1
    margin-right $helper-size-1 !important

.mb-1
    margin-bottom $helper-size-1 !important

.ml-1
    margin-left $helper-size-1 !important

.mx-1
    margin-right $helper-size-1 !important
    margin-left $helper-size-1 !important

.my-1
    margin-top $helper-size-1 !important
    margin-bottom $helper-size-1 !important

.m-2
    margin $helper-size-2 !important

.mt-2
    margin-top $helper-size-2 !important

.mr-2
    margin-right $helper-size-2 !important

.mb-2
    margin-bottom $helper-size-2 !important

.ml-2
    margin-left $helper-size-2 !important

.mx-2
    margin-right $helper-size-2 !important
    margin-left $helper-size-2 !important

.my-2
    margin-top $helper-size-2 !important
    margin-bottom $helper-size-2 !important

.m-3
    margin $helper-size-3 !important

.mt-3
    margin-top $helper-size-3 !important

.mr-3
    margin-right $helper-size-3 !important

.mb-3
    margin-bottom $helper-size-3 !important

.ml-3
    margin-left $helper-size-3 !important

.mx-3
    margin-right $helper-size-3 !important
    margin-left $helper-size-3 !important

.my-3
    margin-top $helper-size-3 !important
    margin-bottom $helper-size-3 !important

.m-4
    margin $helper-size-4 !important

.mt-4
    margin-top $helper-size-4 !important

.mr-4
    margin-right $helper-size-4 !important

.mb-4
    margin-bottom $helper-size-4 !important

.ml-4
    margin-left $helper-size-4 !important

.mx-4
    margin-right $helper-size-4 !important
    margin-left $helper-size-4 !important

.my-4
    margin-top $helper-size-4 !important
    margin-bottom $helper-size-4 !important

.m-5
    margin $helper-size-5 !important

.mt-5
    margin-top $helper-size-5 !important

.mr-5
    margin-right $helper-size-5 !important

.mb-5
    margin-bottom $helper-size-5 !important

.ml-5
    margin-left $helper-size-5 !important

.mx-5
    margin-right $helper-size-5 !important
    margin-left $helper-size-5 !important

.my-5
    margin-top $helper-size-5 !important
    margin-bottom $helper-size-5 !important

.m-auto
    margin auto !important

.mt-auto
    margin-top auto !important

.mr-auto
    margin-right auto !important

.mb-auto
    margin-bottom auto !important

.ml-auto
    margin-left auto !important

.mx-auto
    margin-right auto !important
    margin-left auto !important

.my-auto
    margin-top auto !important
    margin-bottom auto !important

@media (min-width: $grid-sm)
    .m-sm-0
        margin 0 !important
    .mt-sm-0
        margin-top 0 !important
    .mr-sm-0
        margin-right 0 !important
    .mb-sm-0
        margin-bottom 0 !important
    .ml-sm-0
        margin-left 0 !important
    .mx-sm-0
        margin-right 0 !important
        margin-left 0 !important
    .my-sm-0
        margin-top 0 !important
        margin-bottom 0 !important
    .m-sm-1
        margin $helper-size-1 !important
    .mt-sm-1
        margin-top $helper-size-1 !important
    .mr-sm-1
        margin-right $helper-size-1 !important
    .mb-sm-1
        margin-bottom $helper-size-1 !important
    .ml-sm-1
        margin-left $helper-size-1 !important
    .mx-sm-1
        margin-right $helper-size-1 !important
        margin-left $helper-size-1 !important
    .my-sm-1
        margin-top $helper-size-1 !important
        margin-bottom $helper-size-1 !important
    .m-sm-2
        margin $helper-size-2 !important
    .mt-sm-2
        margin-top $helper-size-2 !important
    .mr-sm-2
        margin-right $helper-size-2 !important
    .mb-sm-2
        margin-bottom $helper-size-2 !important
    .ml-sm-2
        margin-left $helper-size-2 !important
    .mx-sm-2
        margin-right $helper-size-2 !important
        margin-left $helper-size-2 !important
    .my-sm-2
        margin-top $helper-size-2 !important
        margin-bottom $helper-size-2 !important
    .m-sm-3
        margin $helper-size-3 !important
    .mt-sm-3
        margin-top $helper-size-3 !important
    .mr-sm-3
        margin-right $helper-size-3 !important
    .mb-sm-3
        margin-bottom $helper-size-3 !important
    .ml-sm-3
        margin-left $helper-size-3 !important
    .mx-sm-3
        margin-right $helper-size-3 !important
        margin-left $helper-size-3 !important
    .my-sm-3
        margin-top $helper-size-3 !important
        margin-bottom $helper-size-3 !important
    .m-sm-4
        margin $helper-size-4 !important
    .mt-sm-4
        margin-top $helper-size-4 !important
    .mr-sm-4
        margin-right $helper-size-4 !important
    .mb-sm-4
        margin-bottom $helper-size-4 !important
    .ml-sm-4
        margin-left $helper-size-4 !important
    .mx-sm-4
        margin-right $helper-size-4 !important
        margin-left $helper-size-4 !important
    .my-sm-4
        margin-top $helper-size-4 !important
        margin-bottom $helper-size-4 !important
    .m-sm-5
        margin $helper-size-5 !important
    .mt-sm-5
        margin-top $helper-size-5 !important
    .mr-sm-5
        margin-right $helper-size-5 !important
    .mb-sm-5
        margin-bottom $helper-size-5 !important
    .ml-sm-5
        margin-left $helper-size-5 !important
    .mx-sm-5
        margin-right $helper-size-5 !important
        margin-left $helper-size-5 !important
    .my-sm-5
        margin-top $helper-size-5 !important
        margin-bottom $helper-size-5 !important
    .m-sm-auto
        margin auto !important
    .mt-sm-auto
        margin-top auto !important
    .mr-sm-auto
        margin-right auto !important
    .mb-sm-auto
        margin-bottom auto !important
    .ml-sm-auto
        margin-left auto !important
    .mx-sm-auto
        margin-right auto !important
        margin-left auto !important
    .my-sm-auto
        margin-top auto !important
        margin-bottom auto !important

@media (min-width: $grid-md)
    .m-md-0
        margin 0 !important
    .mt-md-0
        margin-top 0 !important
    .mr-md-0
        margin-right 0 !important
    .mb-md-0
        margin-bottom 0 !important
    .ml-md-0
        margin-left 0 !important
    .mx-md-0
        margin-right 0 !important
        margin-left 0 !important
    .my-md-0
        margin-top 0 !important
        margin-bottom 0 !important
    .m-md-1
        margin $helper-size-1 !important
    .mt-md-1
        margin-top $helper-size-1 !important
    .mr-md-1
        margin-right $helper-size-1 !important
    .mb-md-1
        margin-bottom $helper-size-1 !important
    .ml-md-1
        margin-left $helper-size-1 !important
    .mx-md-1
        margin-right $helper-size-1 !important
        margin-left $helper-size-1 !important
    .my-md-1
        margin-top $helper-size-1 !important
        margin-bottom $helper-size-1 !important
    .m-md-2
        margin $helper-size-2 !important
    .mt-md-2
        margin-top $helper-size-2 !important
    .mr-md-2
        margin-right $helper-size-2 !important
    .mb-md-2
        margin-bottom $helper-size-2 !important
    .ml-md-2
        margin-left $helper-size-2 !important
    .mx-md-2
        margin-right $helper-size-2 !important
        margin-left $helper-size-2 !important
    .my-md-2
        margin-top $helper-size-2 !important
        margin-bottom $helper-size-2 !important
    .m-md-3
        margin $helper-size-3 !important
    .mt-md-3
        margin-top $helper-size-3 !important
    .mr-md-3
        margin-right $helper-size-3 !important
    .mb-md-3
        margin-bottom $helper-size-3 !important
    .ml-md-3
        margin-left $helper-size-3 !important
    .mx-md-3
        margin-right $helper-size-3 !important
        margin-left $helper-size-3 !important
    .my-md-3
        margin-top $helper-size-3 !important
        margin-bottom $helper-size-3 !important
    .m-md-4
        margin $helper-size-4 !important
    .mt-md-4
        margin-top $helper-size-4 !important
    .mr-md-4
        margin-right $helper-size-4 !important
    .mb-md-4
        margin-bottom $helper-size-4 !important
    .ml-md-4
        margin-left $helper-size-4 !important
    .mx-md-4
        margin-right $helper-size-4 !important
        margin-left $helper-size-4 !important
    .my-md-4
        margin-top $helper-size-4 !important
        margin-bottom $helper-size-4 !important
    .m-md-5
        margin $helper-size-5 !important
    .mt-md-5
        margin-top $helper-size-5 !important
    .mr-md-5
        margin-right $helper-size-5 !important
    .mb-md-5
        margin-bottom $helper-size-5 !important
    .ml-md-5
        margin-left $helper-size-5 !important
    .mx-md-5
        margin-right $helper-size-5 !important
        margin-left $helper-size-5 !important
    .my-md-5
        margin-top $helper-size-5 !important
        margin-bottom $helper-size-5 !important
    .m-md-auto
        margin auto !important
    .mt-md-auto
        margin-top auto !important
    .mr-md-auto
        margin-right auto !important
    .mb-md-auto
        margin-bottom auto !important
    .ml-md-auto
        margin-left auto !important
    .mx-md-auto
        margin-right auto !important
        margin-left auto !important
    .my-md-auto
        margin-top auto !important
        margin-bottom auto !important

@media (min-width: $grid-lg)
    .m-lg-0
        margin 0 !important
    .mt-lg-0
        margin-top 0 !important
    .mr-lg-0
        margin-right 0 !important
    .mb-lg-0
        margin-bottom 0 !important
    .ml-lg-0
        margin-left 0 !important
    .mx-lg-0
        margin-right 0 !important
        margin-left 0 !important
    .my-lg-0
        margin-top 0 !important
        margin-bottom 0 !important
    .m-lg-1
        margin $helper-size-1 !important
    .mt-lg-1
        margin-top $helper-size-1 !important
    .mr-lg-1
        margin-right $helper-size-1 !important
    .mb-lg-1
        margin-bottom $helper-size-1 !important
    .ml-lg-1
        margin-left $helper-size-1 !important
    .mx-lg-1
        margin-right $helper-size-1 !important
        margin-left $helper-size-1 !important
    .my-lg-1
        margin-top $helper-size-1 !important
        margin-bottom $helper-size-1 !important
    .m-lg-2
        margin $helper-size-2 !important
    .mt-lg-2
        margin-top $helper-size-2 !important
    .mr-lg-2
        margin-right $helper-size-2 !important
    .mb-lg-2
        margin-bottom $helper-size-2 !important
    .ml-lg-2
        margin-left $helper-size-2 !important
    .mx-lg-2
        margin-right $helper-size-2 !important
        margin-left $helper-size-2 !important
    .my-lg-2
        margin-top $helper-size-2 !important
        margin-bottom $helper-size-2 !important
    .m-lg-3
        margin $helper-size-3 !important
    .mt-lg-3
        margin-top $helper-size-3 !important
    .mr-lg-3
        margin-right $helper-size-3 !important
    .mb-lg-3
        margin-bottom $helper-size-3 !important
    .ml-lg-3
        margin-left $helper-size-3 !important
    .mx-lg-3
        margin-right $helper-size-3 !important
        margin-left $helper-size-3 !important
    .my-lg-3
        margin-top $helper-size-3 !important
        margin-bottom $helper-size-3 !important
    .m-lg-4
        margin $helper-size-4 !important
    .mt-lg-4
        margin-top $helper-size-4 !important
    .mr-lg-4
        margin-right $helper-size-4 !important
    .mb-lg-4
        margin-bottom $helper-size-4 !important
    .ml-lg-4
        margin-left $helper-size-4 !important
    .mx-lg-4
        margin-right $helper-size-4 !important
        margin-left $helper-size-4 !important
    .my-lg-4
        margin-top $helper-size-4 !important
        margin-bottom $helper-size-4 !important
    .m-lg-5
        margin $helper-size-5 !important
    .mt-lg-5
        margin-top $helper-size-5 !important
    .mr-lg-5
        margin-right $helper-size-5 !important
    .mb-lg-5
        margin-bottom $helper-size-5 !important
    .ml-lg-5
        margin-left $helper-size-5 !important
    .mx-lg-5
        margin-right $helper-size-5 !important
        margin-left $helper-size-5 !important
    .my-lg-5
        margin-top $helper-size-5 !important
        margin-bottom $helper-size-5 !important
    .m-lg-auto
        margin auto !important
    .mt-lg-auto
        margin-top auto !important
    .mr-lg-auto
        margin-right auto !important
    .mb-lg-auto
        margin-bottom auto !important
    .ml-lg-auto
        margin-left auto !important
    .mx-lg-auto
        margin-right auto !important
        margin-left auto !important
    .my-lg-auto
        margin-top auto !important
        margin-bottom auto !important

@media (min-width: $grid-xl)
    .m-xl-0
        margin 0 !important
    .mt-xl-0
        margin-top 0 !important
    .mr-xl-0
        margin-right 0 !important
    .mb-xl-0
        margin-bottom 0 !important
    .ml-xl-0
        margin-left 0 !important
    .mx-xl-0
        margin-right 0 !important
        margin-left 0 !important
    .my-xl-0
        margin-top 0 !important
        margin-bottom 0 !important
    .m-xl-1
        margin $helper-size-1 !important
    .mt-xl-1
        margin-top $helper-size-1 !important
    .mr-xl-1
        margin-right $helper-size-1 !important
    .mb-xl-1
        margin-bottom $helper-size-1 !important
    .ml-xl-1
        margin-left $helper-size-1 !important
    .mx-xl-1
        margin-right $helper-size-1 !important
        margin-left $helper-size-1 !important
    .my-xl-1
        margin-top $helper-size-1 !important
        margin-bottom $helper-size-1 !important
    .m-xl-2
        margin $helper-size-2 !important
    .mt-xl-2
        margin-top $helper-size-2 !important
    .mr-xl-2
        margin-right $helper-size-2 !important
    .mb-xl-2
        margin-bottom $helper-size-2 !important
    .ml-xl-2
        margin-left $helper-size-2 !important
    .mx-xl-2
        margin-right $helper-size-2 !important
        margin-left $helper-size-2 !important
    .my-xl-2
        margin-top $helper-size-2 !important
        margin-bottom $helper-size-2 !important
    .m-xl-3
        margin $helper-size-3 !important
    .mt-xl-3
        margin-top $helper-size-3 !important
    .mr-xl-3
        margin-right $helper-size-3 !important
    .mb-xl-3
        margin-bottom $helper-size-3 !important
    .ml-xl-3
        margin-left $helper-size-3 !important
    .mx-xl-3
        margin-right $helper-size-3 !important
        margin-left $helper-size-3 !important
    .my-xl-3
        margin-top $helper-size-3 !important
        margin-bottom $helper-size-3 !important
    .m-xl-4
        margin $helper-size-4 !important
    .mt-xl-4
        margin-top $helper-size-4 !important
    .mr-xl-4
        margin-right $helper-size-4 !important
    .mb-xl-4
        margin-bottom $helper-size-4 !important
    .ml-xl-4
        margin-left $helper-size-4 !important
    .mx-xl-4
        margin-right $helper-size-4 !important
        margin-left $helper-size-4 !important
    .my-xl-4
        margin-top $helper-size-4 !important
        margin-bottom $helper-size-4 !important
    .m-xl-5
        margin $helper-size-5 !important
    .mt-xl-5
        margin-top $helper-size-5 !important
    .mr-xl-5
        margin-right $helper-size-5 !important
    .mb-xl-5
        margin-bottom $helper-size-5 !important
    .ml-xl-5
        margin-left $helper-size-5 !important
    .mx-xl-5
        margin-right $helper-size-5 !important
        margin-left $helper-size-5 !important
    .my-xl-5
        margin-top $helper-size-5 !important
        margin-bottom $helper-size-5 !important
    .m-xl-auto
        margin auto !important
    .mt-xl-auto
        margin-top auto !important
    .mr-xl-auto
        margin-right auto !important
    .mb-xl-auto
        margin-bottom auto !important
    .ml-xl-auto
        margin-left auto !important
    .mx-xl-auto
        margin-right auto !important
        margin-left auto !important
    .my-xl-auto
        margin-top auto !important
        margin-bottom auto !important