for num in 10 20 30 40 50 60 70 80 90 100 110 120 130 140 150
	.padding-top-{num}
		padding-top num px

		@media (max-width $grid-sm)
			padding-top (num / 2) px


	.padding-bottom-{num}
		padding-bottom num px

		@media (max-width $grid-sm)
			padding-bottom (num / 2) px

