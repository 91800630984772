.lead
    font-size 1.25rem
    font-weight 300

hr
    margin-top 1rem
    margin-bottom 1rem
    border 0
    border-top $hr-border-width solid $hr-border-color

small,
.small
    font-size 80%
    font-weight $font-weight-normal

mark,
.mark
    padding 0.2em
    background-color #fbec7a

.list-unstyled
    padding-left 0
    list-style none

.list-inline
    padding-left 0
    list-style none

.list-inline-item
    display inline-block
    &:not(:last-child)
        margin-right 5px

.initialism
    font-size 90%
    text-transform uppercase

.blockquote
    margin-bottom 1rem
    font-size 1.25rem

.blockquote-footer
    display block
    font-size 80%
    color $secondary
    &::before
        content "\2014 \00A0"

.img-fluid
    max-width 100%
    height auto

.img-thumbnail
    padding 0.25rem
    background-color $white
    border 1px solid #ddd
    border-radius $border-radius
    transition all 0.2s ease-in-out
    max-width 100%
    height auto

.figure
    display inline-block

.figure-img
    margin-bottom 0.5rem
    line-height 1

.figure-caption
    font-size 90%
    color $secondary

code,
kbd,
pre,
samp
    font-family $font-family-monospace

code
    padding 0.2rem 0.4rem
    font-size 90%
    color #bd4147
    background-color $light
    border-radius $border-radius

a
    & > code
        padding 0
        color inherit
        background-color inherit

kbd
    padding 0.2rem 0.4rem
    font-size 90%
    color $white
    background-color #212529
    border-radius 0.2rem
    kbd
        padding 0
        font-size 100%
        font-weight $font-weight-bold

pre
    display block
    margin-top 0
    margin-bottom 1rem
    font-size 90%
    color #212529
    code
        padding 0
        font-size inherit
        color inherit
        background-color transparent
        border-radius 0

.pre-scrollable
    max-height 340px
    overflow-y scroll
