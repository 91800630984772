.offset-1
    margin-left 8.333333%

.offset-2
    margin-left 16.666667%

.offset-3
    margin-left 25%

.offset-4
    margin-left 33.333333%

.offset-5
    margin-left 41.666667%

.offset-6
    margin-left 50%

.offset-7
    margin-left 58.333333%

.offset-8
    margin-left 66.666667%

.offset-9
    margin-left 75%

.offset-10
    margin-left 83.333333%

.offset-11
    margin-left 91.666667%

@media (min-width: $grid-sm)
    .offset-sm-1
        margin-left 8.333333%

    .offset-sm-2
        margin-left 16.666667%

    .offset-sm-3
        margin-left 25%

    .offset-sm-4
        margin-left 33.333333%

    .offset-sm-5
        margin-left 41.666667%

    .offset-sm-6
        margin-left 50%

    .offset-sm-7
        margin-left 58.333333%

    .offset-sm-8
        margin-left 66.666667%

    .offset-sm-9
        margin-left 75%

    .offset-sm-10
        margin-left 83.333333%

    .offset-sm-11
        margin-left 91.666667%

@media (min-width: $grid-md)
    .offset-md-1
        margin-left 8.333333%

    .offset-md-2
        margin-left 16.666667%

    .offset-md-3
        margin-left 25%

    .offset-md-4
        margin-left 33.333333%

    .offset-md-5
        margin-left 41.666667%

    .offset-md-6
        margin-left 50%

    .offset-md-7
        margin-left 58.333333%

    .offset-md-8
        margin-left 66.666667%

    .offset-md-9
        margin-left 75%

    .offset-md-10
        margin-left 83.333333%

    .offset-md-11
        margin-left 91.666667%

@media (min-width: $grid-lg)
    .offset-lg-1
        margin-left 8.333333%

    .offset-lg-2
        margin-left 16.666667%

    .offset-lg-3
        margin-left 25%

    .offset-lg-4
        margin-left 33.333333%

    .offset-lg-5
        margin-left 41.666667%

    .offset-lg-6
        margin-left 50%

    .offset-lg-7
        margin-left 58.333333%

    .offset-lg-8
        margin-left 66.666667%

    .offset-lg-9
        margin-left 75%

    .offset-lg-10
        margin-left 83.333333%

    .offset-lg-11
        margin-left 91.666667%

@media (min-width: $grid-xl)
    .offset-xl-1
        margin-left 8.333333%

    .offset-xl-2
        margin-left 16.666667%

    .offset-xl-3
        margin-left 25%

    .offset-xl-4
        margin-left 33.333333%

    .offset-xl-5
        margin-left 41.666667%

    .offset-xl-6
        margin-left 50%

    .offset-xl-7
        margin-left 58.333333%

    .offset-xl-8
        margin-left 66.666667%

    .offset-xl-9
        margin-left 75%

    .offset-xl-10
        margin-left 83.333333%

    .offset-xl-11
        margin-left 91.666667%
