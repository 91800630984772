.social
	display inline-block
	margin-left 10px
	width 40px
	height 40px
	border 1px solid $white
	border-radius 50%
	background-position center
	opacity .2

	&:hover
		opacity 1

	&-vk
		background-image url('/static/images/social/social-vk.svg')

	&-insta
		background-image url('/static/images/social/social-insta.svg')

	&-face
		background-image url('/static/images/social/social-facebook.svg')
