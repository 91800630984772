.title
	&-h1
		font-size 3.6rem

		@media (max-width: $grid-sm)
			font-size 2.2rem

	&-h2
		font-size 3rem

		@media (max-width: $grid-sm)
			font-size 2rem

	&-h3
		font-size 2.4rem

		@media (max-width: $grid-sm)
			font-size 1.8rem

	&-h4
		font-weight 400
		font-size 1.8rem

	&-h5
		color alpha($black, .6)
		font-size 1.6rem

	&__sale
		color $primary
		font-size 3.2rem
