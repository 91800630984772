@media print  
    *, *::before, *::after
        text-shadow none !important
        box-shadow none !important
    a, a:visited
        text-decoration underline
    abbr[title]::after
        content " (" attr(title) ")"
    pre
        white-space pre-wrap !important
    thead
        display table-header-group
    .navbar
        display none
    .badge
        border 1px solid $black
    .table
        border-collapse collapse !important
    pre,blockquote
        border 1px solid #999
        page-break-inside avoid
    tr,img
        page-break-inside avoid
    p,h2, h3
        orphans 3
        widows 3
    h2, h3
        page-break-after avoid
    .table td,.table th
        background-color $white !important
    .table-bordered th, .table-bordered td
        border 1px solid #ddd !important