.table
    width 100%
    max-width 100%
    margin-bottom 1rem
    background-color transparent
    thead
        th
            vertical-align bottom
            border-bottom 2px solid #e9ecef
    tbody
        & + tbody
            border-top 2px solid #e9ecef
    .table
        background-color $white

.table th,
.table td
    padding 0.75rem
    vertical-align top
    border-top 1px solid #e9ecef

.table-sm th,
.table-sm td
    padding 0.3rem

.table-bordered
    border 1px solid #e9ecef

.table-bordered th,
.table-bordered td
    border 1px solid #e9ecef

.table-bordered thead th,
.table-bordered thead td
    border-bottom-width 2px

.table-borderless
    th,
    td,
    thead th,
    tbody + tbody
        border 0


.table-striped
    tbody
        tr
            &:nth-of-type(odd)
                background-color rgba(0, 0, 0, 0.05)

.table-hover
    tbody
        tr
            &:hover
                background-color rgba(0, 0, 0, 0.075)
    .table-primary
        &:hover
            background-color #9fcdff
    .table-secondary
        &:hover
            background-color #cfd2d6
    .table-success
        &:hover
            background-color #b1dfbb
    .table-info
        &:hover
            background-color #abdde5
    .table-warning
        &:hover
            background-color #ffe8a1
    .table-danger
        &:hover
            background-color #f1b0b7
    .table-light
        &:hover
            background-color #ececf6
    .table-dark
        &:hover
            background-color #b9bbbe
    .table-active
        &:hover
            background-color rgba(0, 0, 0, 0.075)

.table-primary,
.table-primary > th,
.table-primary > td
    background-color #b8daff

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th
    background-color #9fcdff

.table-secondary,
.table-secondary > th,
.table-secondary > td
    background-color #dddfe2

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th
    background-color #cfd2d6

.table-success,
.table-success > th,
.table-success > td
    background-color #c3e6cb

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th
    background-color #b1dfbb

.table-info,
.table-info > th,
.table-info > td
    background-color #bee5eb

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th
    background-color #abdde5

.table-warning,
.table-warning > th,
.table-warning > td
    background-color #ffeeba

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th
    background-color #ffe8a1

.table-danger,
.table-danger > th,
.table-danger > td
    background-color #f5c6cb

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th
    background-color #f1b0b7

.table-light,
.table-light > th,
.table-light > td
    background-color #fdfdfe

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th
    background-color #ececf6

.table-dark,
.table-dark > th,
.table-dark > td
    background-color #c6c8ca

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th
    background-color #b9bbbe

.table-active,
.table-active > th,
.table-active > td
    background-color rgba(0, 0, 0, 0.075)

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th
    background-color rgba(0, 0, 0, 0.075)

.thead-inverse
    th
        color $white
        background-color #212529

.thead-default
    th
        color #495057
        background-color #e9ecef

.table-inverse
    color $white
    background-color #212529
    &.table-bordered
        border 0
    &.table-striped
        tbody
            tr
                &:nth-of-type(odd)
                    background-color rgba(255, 255, 255, 0.05)
    &.table-hover
        tbody
            tr
                &:hover
                    background-color rgba(255, 255, 255, 0.075)

.table-inverse th,
.table-inverse td,
.table-inverse thead th
    border-color #32383e

@media (max-width: 991px)
    .table-responsive
        display block
        width 100%
        overflow-x auto
        -ms-overflow-style -ms-autohiding-scrollbar
        &.table-bordered
            border 0
