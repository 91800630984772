.footer
	padding 40px 0
	// background-color $footerBackground
	background-color lighten(#05263f, 30%) //
	font-size 1.3rem
	color $white

	@media (max-width $grid-md)
		background-color lighten(#05263f, 20%) //


	&__search
		margin-right 50px

		@media (max-width $grid-md)
			margin-right 0
			margin-bottom 60px

	.search
		border-bottom 1px solid alpha($white, .5)

		input
			&::placeholder
				color alpha($white, .5)

		button
			opacity .5

			&:hover
				opacity .7


	&__address

		a
			line-height 18px
			// color $footerLink
			color darken(white, 10%) //

		@media (max-width $grid-md)
			margin-right -15px
			margin-bottom 50px


	&__social
		text-align right

		.social
			opacity .6 //

		@media (max-width $grid-md)

			.social
				display block
				margin-left 10px
				margin-bottom 20px


	&__line
		margin 40px 0
		width 100%
		height 1px
		border 0
		// background-color alpha($white, .2)
		background-color alpha($white, .5)

		@media (max-width $grid-md)
			display none


	&__copyright
		position relative
		padding-left 15px

		&:before
			content '©'
			top 2px
			left 0
			position absolute
			// color $footerLink
			color $white //

		span
			// color $footerLink
			color darken(white, 10%) //

		@media (max-width $grid-md)
			padding 0
			margin-top -50px
			line-height 30px

			&:before
				position relative


	&__politics

		a
			// color $footerLink
			color darken(white, 10%) //

		@media (max-width  $grid-md)
			margin-bottom 50px


	&__contacts
		text-align right
		color $footerLink

		a
			color $white

		@media (max-width $grid-md)
			text-align left


	&__container
		max-width 1200px
