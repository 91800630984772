.btn
	display inline-block
	padding 12px
	outline none
	border 1px solid alpha($black, .2)
	border-radius 5px
	background-color transparent
	color alpha($black, .4)
	font-size 1.6rem
	cursor pointer

	&:hover
		border-color $primary
		color $primary
		text-decoration none

	&-block
		display block
		width 100%

	&-primary
		border-color $primary
		background-color $primary
		color $white

		&:hover
			box-shadow 0 3px 4px alpha($primary, .4)
			color $white

	&-whatsapp
		display inline-flex
		align-items center
		background-color #455a64
		color $white
		font-weight 700
		font-size 1.4rem

		&:before
			display block
			margin-right 1rem
			width 25px
			height 25px
			background-image url('/static/images/modal/modal__whatsapp.svg')
			background-repeat no-repeat
			content ''

		&:hover,
		&:focus
			border-color #075E54
			background-color #075E54
			color $white

	&-night
		$night-bg-color = lighten(#05263f, 5%)
		$text-color = #eefaff
		margin-right 5px
		margin-left 5px
		width 40%
		height 5rem
		border-color $text-color
		border-radius 5px
		background-color transparent
		color $text-color

		&:hover
			border-color lighten($text-color, 30%)
			background-color lighten($night-bg-color, 15%)

	&-opacity-70
		opacity .7
