.mission


	&__photo
		// padding-top (360 / 510 * 100)%
		border-radius 10px
		// background-color alpha($black, .1)
		// background-size cover
		background-size 100%
		background-position center
		background-repeat no-repeat
		cursor pointer
