.d-none
    display none !important

.d-inline
    display inline !important

.d-inline-block
    display inline-block !important

.d-block
    display block !important

.d-table
    display table !important

.d-table-cell
    display table-cell !important

.d-flex
    display -ms-flexbox !important
    display flex !important

.d-inline-flex
    display -ms-inline-flexbox !important
    display inline-flex !important

@media (min-width: $grid-sm)
    .d-sm-none
        display none !important
    .d-sm-inline
        display inline !important
    .d-sm-inline-block
        display inline-block !important
    .d-sm-block
        display block !important
    .d-sm-table
        display table !important
    .d-sm-table-cell
        display table-cell !important
    .d-sm-flex
        display -ms-flexbox !important
        display flex !important
    .d-sm-inline-flex
        display -ms-inline-flexbox !important
        display inline-flex !important

@media (min-width: $grid-md)
    .d-md-none
        display none !important
    .d-md-inline
        display inline !important
    .d-md-inline-block
        display inline-block !important
    .d-md-block
        display block !important
    .d-md-table
        display table !important
    .d-md-table-cell
        display table-cell !important
    .d-md-flex
        display -ms-flexbox !important
        display flex !important
    .d-md-inline-flex
        display -ms-inline-flexbox !important
        display inline-flex !important

@media (min-width: $grid-lg)
    .d-lg-none
        display none !important
    .d-lg-inline
        display inline !important
    .d-lg-inline-block
        display inline-block !important
    .d-lg-block
        display block !important
    .d-lg-table
        display table !important
    .d-lg-table-cell
        display table-cell !important
    .d-lg-flex
        display -ms-flexbox !important
        display flex !important
    .d-lg-inline-flex
        display -ms-inline-flexbox !important
        display inline-flex !important

@media (min-width: $grid-xl)
    .d-xl-none
        display none !important
    .d-xl-inline
        display inline !important
    .d-xl-inline-block
        display inline-block !important
    .d-xl-block
        display block !important
    .d-xl-table
        display table !important
    .d-xl-table-cell
        display table-cell !important
    .d-xl-flex
        display -ms-flexbox !important
        display flex !important
    .d-xl-inline-flex
        display -ms-inline-flexbox !important
        display inline-flex !important

.d-print-block
    display none !important

.d-print-inline
    display none !important

.d-print-inline-block
    display none !important

@media print
    .d-print-block
        display block !important
    .d-print-inline
        display inline !important
    .d-print-inline-block
        display inline-block !important
    .d-print-none
        display none !important